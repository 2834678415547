import React from 'react';

// import styled components
import { BannerWrapper } from './styled';

// import image
// import { Rlyeh } from '../Images';

const Banner = () => (
  <>
    <BannerWrapper />
    {/* <Rlyeh /> */}
  </>
);

export default Banner;
